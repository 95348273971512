import loginAr from "./login/ar";
import errorAr from "./error/ar";
import staticAr from "./static/ar";
import epgAr from "./epg/ar";
import toastAr from "./toast/ar";
import monthAr from "./month/ar";
import playerAr from "./player/ar";
import SEOAr from "./seo/ar";

const ar = {
  browse: "browse (ar)",
  ...loginAr,
  ...errorAr,
  ...staticAr,
  ...epgAr,
  ...toastAr,
  ...monthAr,
  ...playerAr,
  ...SEOAr,
  episodeCount: "{count} حلقات",
  durationLabel: "{duration} د",
  playMedia: "اعرض: {media}",
  seasonLabel: "موسم {s}",
  shortSeasonLabel: "S{s}",
  episodeLabel: "حلقة {s}",
  shortEpisodeLabel: "E{s}",
  songDuration: "المدة: {duration}",

  hours: "'' | {h} ساعة | {h} ساعات",
  minutes: "'' | {m} دقيقة | {m} دقائق",
  seconds: "'' | {s} ثانية | {s} ثوانٍ",

  "Follow us on:": "تابعنا على",
  Home: "الرئيسية",
  Series: "مسلسلات",
  Movies: "أفلام",
  Sports: "رياضة",
  News: "أخبار",
  Radio: "راديو",
  Kids: "أطفال",
  "Get started": "البدء",
  "Available on": "متوفر على",
  "Available at": "متوفر على",
  "All Rights Reserved.": "جميع الحقوق محفوظة لمجموعة",
  "Abu Dhabi Media": "Abu Dhabi Media",
  "Terms and Conditions": " الشروط والأحكام",
  "Privacy Policy": "سياسة الخصوصية",
  "Call Us": "إتصل بنا",
  "Who Are We": "من نحن",
  Channels: "قنوات",
  "Live TV Schedule": "دليل البث التلفزيوني",
  "Live Radio Schedule": "دليل البث الإذاعي",
  "Live Sports Schedule": "دليل بث القنوات الرياضية",
  "Exit Kids": "خروج أطفال",
  MenuCartoon: "كرتون",
  MenuExitCartoon: "خروج كرتون",
  Play: "اعرض",
  "Add to my list": "أضف إلى قائمتي",
  "View All": "المزيد",
  "View all": "المزيد",
  Cast: "الممثلين",
  "Similar to this": "عروض مماثلة",
  Episodes: "الحلقات",
  Programs: "برامج",
  Characters: "الشخصيات",
  Songs: "أغاني",
  "Majid TV": "ماجد",
  "My List": "قائمتي",

  "Who's Watching?": "من يشاهد؟",
  "Choose Profile for Editing": "اختر الملف الشخصي للتعديل",
  "Add New": "أضف",

  "Profile Name": "اسم ملفك الشخصي",
  "Your profile name": "اسم ملفك الشخصي",
  Language: "اللغة",
  Gender: "الجنس",
  Arabic: "العربية",
  Male: "ذكر",
  Female: "أنثى",
  "Kids account?": "حساب أطفال؟",
  kidsAccountBlurb:
    "إذا تم اختياره، فلن يتمكن هذا الملف الشخصي من الوصول إلا إلى البرامج التلفزيونية والأفلام المناسبة للأطفال الذين تبلغ أعمارهم 12 عامًا أو أقل",
  "Autoplay next episode in a series on all devices":
    "التشغيل التلقائي للحلقة التالية في السلسلة على جميع الأجهزة",
  "Autoplay previews whilst browsing on all devices":
    "معاينات التشغيل التلقائي أثناء التصفح على جميع الأجهزة",
  "Date of birth": "تاريخ الميلاد",
  "Example: 30/12/1990": "مثال: 30/12/1990",
  DD: "اليوم",
  MM: "الشهر",
  YYYY: "ألسنة",
  "Edit Profile": "تعديل الملف الشخصي",
  "Edit Profiles": "إدارة الملفات الشخصية",
  parentalCodeSetupHeadline:
    "للتأكد من أن محتوى تويوتا الخاص بالأطفال مناسب فقط، يرجى الانتباه إلى رمز الحليب",
  "Make it yours": "إجعلها في قائمتك",
  recommendationsBlurb:
    "نريد مساعدتك في اكتشاف البرامج التلفزيونية والأفلام التي ستستمتع بها تمامًا. اختر على الأقل 3 التي تريدها",
  recommendationsBlurbError: "يرجى اختيار ما لا يقل عن 3 برامج تريدها.",
  nSeasons: "موسم 1 | {n} موسم | {n} مواسم",
  parentalCodeConfirm: "هل أنت متأكد من حذف رمز الوالدين؟",
  deleteAccountTitle: `هل أنت متأكد من حذفحسابك؟`,
  deleteAccountBlurb2: "يرجى الانتباه إلى أن استرداد الحساب غير ممكن بعد الحذف",
  watchTitle: "{m}",
  "Show code": "أظهر الرمز",

  "Do you wish to change this profile to Adult profile?":
    "هل أنت متأكد من إعادة تعيين توصياتك؟",
  "Once converted, you can't go back to Kids' profile":
    "بمجرد حذف ملف التعريف هذا، ستتم إزالة كل ما يرتبط به، مثل قائمتي والتقييمات والنشاط نهائيًا. لن تتمكن من الوصول إليهم مرة أخرى.",
  Yes: "نعم",

  "Enter parental code to continue": "أدخل رمز الوالدين للمتابعة",

  "Deleting account": "حذف الحساب",

  deleteProfileTitle: "هل أنت متأكد من حذف ملف التعريف الخاص بك؟",
  deleteProfileBlurb2:
    "بمجرد حذف ملف التعريف هذا، ستتم إزالة كل ما يرتبط به، مثل قائمتي والتقييمات والنشاط نهائيًا. لن تتمكن من الوصول إليهم مرة أخرى.",
  adOf: "إعلان {c} من {t}",
  accountHolder: "صاحب الحساب",
  "Make it yours": "إجعلها في قائمتك",
  nonExistCountryCode:
    "يبدو أنك تكتب رمز بلد غير موجود، يرجى التحقق وإعادة المحاولة",
  nonExistCountry:
    "يظهر أن الموقع الذي تكتب فيه غير معروف، يرجى التحقق وإعادة المحاولة",
  "Clear all": "إمسح",

  "Choose a profile picture": "اختر صورة الملف الشخصي",
  "Confirm profile picture?": "تغيير الصورة الشخصية؟",
  Current: "الحالية",
  New: "جديد",
  Change: "تغيير",
  Play: "اعرض",
  Join: "تابع",
  Cancel: "إلغاء",
  Skip: "يتخطى",
  Done: "إنتهى",

  "You've reached the maximum number of profiles allowed":
    "لقد وصلت إلى الحد الأقصى لعدد الملفات الشخصية المسموح بها",
  stepper: "الخطوة {step} من {of}",
  "Manage profiles": "إدارة الملفات الشخصية",
  "Account details": "تفاصيل الحساب",
  "Account Details": "تفاصيل الحساب",
  "Personal details": "تفاصيل شخصية",
  "Contact details": "تفاصيل الاتصال",
  "Switch Profile to": "تحويل الملف الشخصي إلى",
  "Please enter a value": "الرجاء إدخال قيمة",
  "Please enter a valid date": "الرجاء إدخال تاريخ صحيح",
  "Please enter a valid email address": "الرجاء إدخال عنوان بريد إلكتروني صالح",
  "Please enter your password": "الرجاء إدخال كلمة المرور الخاصة بك",
  "Nothing found.": "لم يتم العثور على شيء.",
  "Search for Programs and Songs": "ابحث عن برامج وأغاني",
  "Begin your exploration for a world of possibilities. Let the adventure unfold!":
    "ابدأ استكشافك لعالم من الاحتمالات. دع المغامرة تتكشف!",
  "All Channel Schedules": "جداول القنوات",
  "Personalising Your Experience...": "تخصيص تجربتك...",

  noSearchResultMovies: "0 نتائج للأفلام والعروض",
  noSearchResultSports: "0 نتائج للرياضة",
  noSearchResultKidsPrograms: "0 نتيجة لبرامج أطفال",
  noSearchResultKidsSongs: "0 نتيجة لأغاني أطفال",
  noSearchResultRadio: "0 نتيجة للإذاعة",
  "Delete profile": "حذف الحساب",
  Details: "اعرض",
  English: "إنجليزي",
  Security: "الأمن",
  Delete: "حذف",
  "Change parental code": "تغيير رمز الوالدين",
  "Change Parental Code": "تغيير رمز الوالدين",
  "Add parental code": "إضافة رمز الوالدين",
  "Add Parental Code": "إضافة رمز الوالدين",
  "Change password": "تغيير كلمة المرور",
  "Change Password": "تغيير كلمة المرور",
  "Delete account": "حذف الحساب",
  Verified: "تم التحقق",
  "Enter new code": "أدخل الرمز الجديد",
  "Old password": "كلمة المرور الحالية",
  "New password": "كلمة المرور الجديدة",
  "Profile name cannot be empty": "اسم الملف الشخصي لا يمكن أن يكون فارغًا",
  "Profile name cannot exceed 50 characters":
    "لا يمكن أن يتجاوز اسم الملف الشخصي 50 حرفًا",
  "Add Profile": "أضف ملف شخصي",
  "Switch to English": "Switch to English",
  agreeToAeNetwork:
    "بتسجيل الدخول، فإنك توافق على {policy} و {term} الخاصة بـ ADtv",
  adultSearchInputPlaceholder: "ابحث عن المسلسلات والبرامج والأفلام",
  moviesAndShows: "أفلام وعروض",
  radioSeries: "مسلسلات إذاعية",
  kidsPrograms: "برامج أطفال",
  kidsSongs: "أغاني أطفال",
  genres: "الفئة",
  "Your list is empty, start browsing...":
    "القائمة الخاصة بك فارغة ، يرجى البدء في تصفح",
  "Oldest to newest": "من أقدم إلى أحدث",
  "Newest to oldest": "من الجديد إلى القديم",
  "Remove from my list": "إزالة من قائمتي",
  NoData: "لا توجد بيانات",
  linkTvPrompt: "تسجيل الدخول عبر تطبيق الشاشة الذكية",
  linkTvSubPrompt: "أدخل الرمز للمتابعة",
  "Already have an account?": "هل لديك حساب؟",
  "New to ADTV?": "هل أنت جديد على ADTV؟",
  "Code is incorrect": "الرمز غير صحيح",
  "Please verify your account either in the app or on the website. Login was unsuccessful.":
    "الرجاء التحقق من حسابك إما في التطبيق أو على الموقع. فشل تسجيل الدخول.",
  "The code entered is either invalid or expired. Please try again.":
    "الرمز الذي تم إدخاله إما غير صالح أو منتهي الصلاحية. حاول مرة اخرى.",

  genericErrorTitle: "عذرًا! حدث خطأ ما",
  genericError1:
    "استمر في الانتظار! فريقنا يعمل على إصلاح الأمور. يرجى التحقق من اتصالك أو إعادة التحديث. استكشف محتوى جديد بينما نعالج المشكلة.",
  genericError2: "!شكرًا لصبرك - سنعود قريبًا",
  techTitle: "صعوبات فنية",
  techError1:
    "عذرًا! خوادمنا في حالة استراحة سريعة، حيث يتم إصلاح خطأ داخلي لإعادتك إلى المسار الصحيح.",
  techError2:
    "شكرا لتفهمك. لا تتردد في التواصل مع الدعم للحصول على المساعدة المباشرة.",
  navErrorTitle: "خطأ في تحديد الصفحة",
  navError1: "نعتذر، ولكن تعذر العثور على المحتوى المطلوب.",
  navError2:
    "يرجى التحقق من عنوان URL أو زيارة صفحتنا الرئيسية. للحصول على المساعدة، اتصل بفريق الدعم لدينا.",
  "No Episodes found.": "",
  "Try again": "",
  "Unable to load episodes.": "",
  backToHome: "العودة إلى الصفحة الرئيسية",
  skipAd: "تخطى الإعلان",
  trailer: "برومو",
};

export default ar;
