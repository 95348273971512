import loginEn from "./login/en";
import errorEn from "./error/en";
import staticEn from "./static/en";
import epgEn from "./epg/en";
import toastEn from "./toast/en";
import playerEn from "./player/en";
import SEOEn from "./seo/en";

const en = {
  browse: "browse (en)",
  ...loginEn,
  ...errorEn,
  ...staticEn,
  ...toastEn,
  ...epgEn,
  ...playerEn,
  ...SEOEn,
  episodeCount: "{count} Episodes",
  durationLabel: "{duration} MIN",
  playMedia: "Play: {media}",
  seasonLabel: "Season {s}",
  shortSeasonLabel: "S{s}",
  episodeLabel: "Episode {s}",
  shortEpisodeLabel: "E{s}",
  songDuration: "Duration: {duration}",
  kidsAccountBlurb:
    "If chosen, this profile will only have access to TV shows and movies suitable for kids aged 12 and under",
  parentalCodeSetupHeadline:
    "To ensure that kids only view appropriate content, kindly set a parental code",
  recommendationsBlurb:
    "We want to help you discover TV shows and movies that you'll absolutely enjoy. Pick at least 3 that you like",
  recommendationsBlurbError: "Please pick at least 3 shows that you like.",
  deleteAccountBlurb:
    "You can delete your account in Settings which can be found in the profile menu, on the homepage after you’ve logged in.",
  deleteProfileBlurb:
    "Once you delete this profile, everything associated with it, such as My List, ratings, and activity, will be permanently removed. You won't be able to access them again.",

  nSeasons: "1 Season | {n} Season | {n} Seasons",
  hours: "'' | {h} hr | {h} hrs",
  minutes: "'' | {m} min | {m} mins",
  seconds: "'' | {s} sec | {s} secs",

  stepper: "Step {step} of {of}",
  willExpireIn: "The link will expire in {hours} hours",
  codeWillExpireIn: "The code will expire in {hours} hours",
  // resendIn:
  //   "Resend link | Resend link in {seconds} sec | Resend link in {seconds} secs",

  resendIn: "Resend link | Resend link in 1 sec | Resend link in {count} secs",
  resendCodeIn:
    "Resend code | Resend code in 1 sec | Resend code in {count} secs",
  parentalCodeConfirm: "Are you sure to remove the parental code?",

  deleteAccountTitle: "Are you sure to delete your account?",
  deleteAccountBlurb2:
    "Please note that account recovery is not possible after deletion.",
  watchTitle: "{m}",

  deleteAccountBlurb:
    "You can delete your account in Settings which can be found in the profile menu, on the homepage after you’ve logged in.",

  deleteProfileTitle: "Are you sure to delete your profile?",
  deleteProfileBlurb2:
    "Once you delete this profile, everything associated with it, such as My List, ratings, and activity, will be permanently removed. You won't be able to access them again.",
  adOf: "Advertisement {c} of {t}",
  accountHolder: "Account holder",
  nonExistCountry:
    "Seems that you’re typing in a non-existing location, please check and re-try",
  nonExistCountryCode:
    "Seems that you’re typing in a non-existing country code, please check and re-try",

  noSearchResultMovies: "0 results for movies and shows",
  noSearchResultSports: "0 results for sports",
  noSearchResultKidsPrograms: "0 results for kids programs",
  noSearchResultKidsSongs: "0 results for kids songs",
  noSearchResultRadio: "0 results for radio",
  agreeToAeNetwork: "By logging in, you agree to ADtv's",
  adultSearchInputPlaceholder: "Search series, programs, movies",
  moviesAndShows: "Movies & Shows",
  radioSeries: "Radio Series",
  kidsPrograms: "Kids Programs",
  kidsSongs: "Kids Songs",
  genres: "Genres",
  NoData: "No Data",

  linkTvPrompt: "Log in via the smart screen application",
  linkTvSubPrompt: "Enter code to continue",

  genericErrorTitle: "Oops! Something went wrong",
  genericError1:
    "Hold tight! Our team is fixing things. Please check your connection or refresh. Explore new content while we sort it out.",
  genericError2: "Thanks for your patience - back soon!",
  techTitle: "Technical Difficulties",
  techError1:
    "Oops! Our servers are on a quick break, fixing an internal error to get you back on track.",
  techError2:
    "Thanks for your understanding. Feel free to reach out to support for direct assistance.",
  navErrorTitle: "Navigation Error",
  navError1: "We apologize, but the requested content couldn't be found.",
  navError2:
    "Please check the URL or visit our homepage. For assistance, contact our support team.",
  backToHome: "Back to Home",
  skipAd: "Skip Ad",
  trailer: "Trailer",
  MenuCartoon: "Cartoon",
  MenuExitCartoon: "Exit Cartoon",
};

export default en;
